<template>
  <v-main>
    <v-card class="mb-4" :loading="!user">
      <v-card-title>
        {{ $t("dashboard.title") }}
      </v-card-title>
      <v-card-text v-if="user">
        {{ $t("dashboard.welcome") }} {{ user.firstname }}
      </v-card-text>
      <div class="pb-1">
        <PasswordHintBox />
      </div>
    </v-card>

    <v-card v-if="hasTimingPointsAccount">
      <div class="d-flex justify-space-between">
        <v-card-title>
          {{ $t("dashboard.trigger") }}
        </v-card-title>

        <div class="d-flex align-center">
          <v-btn depressed small class="ma-4" @click="clearTrigger">
            {{ $t("trigger.clear") }}
          </v-btn>
        </div>
      </div>

      <v-data-table
        :headers="triggerHeaders"
        :items="indexedTriggerWithDeviceData"
        mobile-breakpoint="0"
        show-expand
      >
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-text-field
                v-model="search.trigger.deviceId"
                type="text"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="search.trigger.deviceName"
                type="text"
              ></v-text-field>
            </td>
            <td>
              <v-select
                v-model="search.trigger.status"
                :items="triggerStatus"
                :item-text="item => $t(`trigger.table.${item.text}`)"
                clearable
              ></v-select>
            </td>
            <td>
              <v-text-field
                v-model="search.trigger.startNumber"
                type="number"
                min="1"
              ></v-text-field>
            </td>
            <td>
              <v-select
                v-model="search.trigger.type"
                :items="triggerTypes"
                :item-text="item => $t(`trigger.table.${item.text}`)"
                clearable
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="search.trigger.timingChannel"
                :items="triggerChannels"
                clearable
              ></v-select>
            </td>
            <td>
              <v-text-field
                v-model="search.trigger.timestamp"
                type="text"
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:item.deviceId="{ item }">
          <router-link
            :to="{ name: 'DevicesEdit', params: { id: item.deviceId } }"
            exact
          >
            {{ item.deviceId }}
          </router-link>
        </template>

        <template v-slot:item.blocked="{ item }">
          <span v-if="item.blocked">
            {{ $t("trigger.table.blocked") }}
          </span>
          <span v-else>
            {{ $t("trigger.table.active") }}
          </span>
        </template>

        <template v-slot:item.startNumber.type="{ item }">
          {{
            $t(`trigger.table.${String(item.startNumber.type).toLowerCase()}`)
          }}
        </template>

        <template v-slot:item.timestamp="{ item }">
          <span style="white-space: nowrap">
            {{ formatTimestamp_100ns(item.timestamp, item.timeOffset, false) }}
          </span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <div class="subtitle font-weight-bold">
                    {{ $t("trigger.table.timestamp") }}
                  </div>
                  <div>{{ item.timestamp }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="subtitle font-weight-bold">
                    {{ $t("trigger.table.offset") }}
                  </div>
                  <div>{{ item.timeOffset }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="subtitle font-weight-bold">
                    {{ $t("trigger.table.type") }}
                  </div>
                  <div>{{ item.type }}</div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <div class="subtitle font-weight-bold">
                    {{ $t("trigger.table.valid") }}
                  </div>
                  <div>
                    <v-icon>{{ validIcon(item.valid) }}</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapMutations } from "vuex";
import formatTimestamp from "@/mixins/formatTimestamp";
import deviceTypes from "@/utils/deviceTypes";
import PasswordHintBox from "@/components/PasswordHintBox";

export default {
  name: "PanelDashboard",
  components: {
    PasswordHintBox
  },
  data() {
    return {
      search: {
        trigger: {
          deviceId: "",
          deviceName: "",
          status: "",
          startNumber: "",
          type: "",
          timingChannel: "",
          timestamp: ""
        }
      },
      triggerStatus: [
        {
          value: false,
          text: "active"
        },
        {
          value: true,
          text: "blocked"
        }
      ],
      triggerTypes: [
        {
          value: "SEQUENTIAL",
          text: "sequential"
        },
        {
          value: "MANUAL",
          text: "manual"
        },
        {
          value: "SUGGESTED",
          text: "suggested"
        },
        {
          value: "MEMO",
          text: "memo"
        },
        {
          value: "UNKNOWN",
          text: "unkown"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      trigger: state => state.trigger.items,
      devices: state => state.devices.items
    }),
    ...mapGetters({
      hasTimingPointsAccount: "user/hasTimingPointsAccount",
      usesOAuth: "user/usesOAuth"
    }),
    triggerHeaders() {
      return [
        {
          text: this.$i18n.t("trigger.table.deviceId"),
          value: "deviceId",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.deviceId).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.deviceName"),
          value: "device.deviceComponents.AppSettings.name",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.deviceName).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.status"),
          value: "blocked",
          filter: value => {
            if (
              this.search.trigger.status !== null &&
              this.search.trigger.status !== undefined &&
              this.search.trigger.status !== ""
            ) {
              return value === this.search.trigger.status;
            }

            return true;
          }
        },
        {
          text: this.$i18n.t("trigger.table.startNumber"),
          value: "startNumber.startNumber",
          align: "right",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.startNumber).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.type"),
          value: "startNumber.type",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.trigger.type).toLowerCase())
        },
        {
          text: this.$i18n.t("trigger.table.timingChannel"),
          value: "timingChannel",
          filter: value => {
            if (
              this.search.trigger.timingChannel !== null &&
              this.search.trigger.timingChannel !== undefined &&
              this.search.trigger.timingChannel !== ""
            ) {
              return String(value)
                .toLowerCase()
                .includes(
                  String(this.search.trigger.timingChannel).toLowerCase()
                );
            }

            return true;
          }
        },
        {
          text: this.$i18n.t("trigger.table.timestamp"),
          value: "timestamp",
          filter: (value, search, item) => {
            return this.formatTimestamp_100ns(value, item.timeOffset)
              .toLowerCase()
              .includes(String(this.search.trigger.timestamp).toLowerCase());
          }
        },
        {
          value: "data-table-expand"
        }
      ];
    },
    indexedTriggerWithDeviceData() {
      if (this.trigger && this.devices) {
        return this.trigger.map((trigger, index) => ({
          id: index,
          ...trigger,
          device: this.$store.getters["devices/getItemById"](trigger.deviceId)
        }));
      }

      return [];
    },
    triggerChannels() {
      return deviceTypes["MT1"].channels.map(channel => ({
        value: channel.name,
        text: channel.name
      }));
    }
  },
  mixins: [formatTimestamp],
  methods: {
    ...mapMutations({
      clearItems: "trigger/clearItems"
    }),
    clearTrigger() {
      this.clearItems();
    },
    validIcon(valid) {
      if (valid) return "mdi-check";

      return "mdi-close";
    }
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
</style>
